import { FC, useEffect, useRef, useState } from 'react';
import { ArticleMediaData } from '~/types';

interface Props extends React.ComponentPropsWithoutRef<'img'> {
  mediaData?: ArticleMediaData;
}

const ResponsiveImage: FC<Props> = ({ mediaData, ...rest }) => {
  const ref = useRef<HTMLImageElement>(null);

  const [sizes, setSizes] = useState('1px');
  useEffect(() => {
    const { current } = ref;

    if (!current) {
      return;
    }

    setSizes(
      Math.ceil(
        (current.getBoundingClientRect().width / window.innerWidth) * 100,
      ) + 'vw',
    );
  }, []);

  if (!mediaData?.thumbnail?.large) {
    return (
      <img src='/img/article-placeholder.png' alt={'placeholder'} {...rest} />
    );
  }

  return (
    <img
      ref={ref}
      src={mediaData?.thumbnail.large ?? '/img/article-placeholder.png'}
      srcSet={mediaData?.thumbnail.responsive}
      alt={'placeholder'}
      sizes={sizes}
      width='100%'
      {...rest}
    />
  );
};

export default ResponsiveImage;
